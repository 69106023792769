<template>
    <v-container fluid class="pa-0">
        <v-toolbar elevation="1" :style="stickyTop">
            <v-toolbar-title>Interviews</v-toolbar-title>
            <v-btn icon @click="fetchSurveys()"><v-icon>mdi-refresh</v-icon></v-btn>
            <v-spacer />
            <!-- ADD SURVEY -->
            <v-btn fab icon @click="showDialogAdd = true" v-if="this.userInfo.role != 'coachee'">
                <v-icon> mdi-plus-circle-outline </v-icon>
            </v-btn>
            <v-dialog width="800" max-width="100vw" v-model="showDialogAdd">
                <AddSurvey v-on:hideDialogAdd="showDialogAdd = false" v-on:refresh="fetchSurveys" />
            </v-dialog>
            <v-spacer />
        </v-toolbar>
        <v-container>
            <v-data-table :items="surveys" :headers="headers" :disable-sort="this.$vuetify.breakpoint.mobile"
                :loading="loading" item-key="id" no-data-text="" no-results-text="">
                <template v-slot:[`item.createdAt`]="{ item }">
                    <span v-if="item.createdAt">{{ item.createdAt.toDate().toDateString() }}</span>
                </template>
                <template v-slot:[`item.coachee`]="{ item }">
                    <span v-if="item.coachee">{{ item.coachee }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn small plain @click="openDetailView(item)" v-show="item.status === 'NEW'">vragenlijst</v-btn>
                    <v-btn small plain @click="showGrid(item)">grid</v-btn>
                    <v-btn small plain @click="openPeers(item)">Peers</v-btn>

                    <v-btn v-show="userInfo.role !== 'coachee'" small plain icon @click="startDelete(item)"
                        class="ml-4"><v-icon small>mdi-delete</v-icon></v-btn>

                    <v-dialog v-model="confirmDelete" width="400" max-width="100vw" hide-overlay>
                        <confirm-delete @confirm="deleteSurvey()" @cancel="confirmDelete = false" />
                    </v-dialog>
                </template>
            </v-data-table>
        </v-container>
    </v-container>
</template>
<script>
import AddSurvey from "@/components/AddSurvey";
import AddPeer from "@/components/AddPeer"
import { db } from "@/services/firebase";
import { doc, query, where, collection, getDocs, updateDoc } from "firebase/firestore";
import { mapGetters } from "vuex";
import ConfirmDelete from '@/components/ConfirmDelete.vue'
export default {
    name: "Surveys",
    components: { AddSurvey, AddPeer, ConfirmDelete },
    data() {
        return {
            currentSurvey: null,
            confirmDelete: false,
            loading: false,
            selectedItem: -1,
            showDialogAdd: false,
            headers: [
                {
                    text: "Gemaakt op",
                    value: "createdAt",
                },
                {
                    text: "Coach",
                    value: "coach.name",
                },
                {
                    text: "Coachee",
                    value: "coachee.name",
                },
                {
                    text: "Status",
                    value: "status",
                },
                {
                    text: "Details",
                    value: "actions",
                },
            ],
            surveys: [],
            surveyId: null
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "session/userInfo",
        }),
        stickyTop() {
            return {
                position: "sticky",
                top: this.$vuetify.application.top + "px",
                zIndex: 1,
            };
        },
    },
    created() {
        this.fetchSurveys();
    },
    methods: {

        async fetchSurveys() {
            this.loading = true
            switch (this.userInfo.role) {
                case "admin":
                    const cRef = collection(db, "surveys");
                    const res = await getDocs(cRef);
                    this.surveys = res.docs.map((doc) => {
                        return { ...doc.data(), id: doc.id };
                    });
                    this.loading = false
                    break;

                case "coach":
                    const q = query(
                        collection(db, "surveys"),
                        where("coach.id", "==", this.userInfo.coach.id)
                    );
                    const surveys = await getDocs(q);
                    this.surveys = surveys.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    this.loading = false
                    break;

                case "coachee":
                    // TBD
                    const q2 = query(
                        collection(db, "surveys"),
                        where("coachee.id", "==", this.userInfo.coachee.id)
                    );
                    const surveys2 = await getDocs(q2);
                    this.surveys = surveys2.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    this.loading = false
                    break;

                default:
                    this.surveys = [];
            }
            this.surveys = this.surveys.filter(s => s.hasOwnProperty('isDeleted') == false)
        },
        openDetailView(_survey) {
            this.$router.push({
                name: "Survey",
                params: { id: _survey.id },
            });
        },
        showGrid(_survey) {
            this.$router.push({
                name: "Grid",
                params: { id: _survey.id, survey: _survey },
            });
        },
        openPeers(_survey) {
            this.$router.push({
                name: "Peers",
                params: { id: _survey.id },
            });
        },
        async deleteSurvey() {
            await updateDoc(
                doc(
                    db,
                    "surveys",
                    this.currentSurvey,
                ),
                {
                    isDeleted: true
                }
            );
            this.confirmDelete = false
            this.fetchSurveys()
        },
        startDelete(_survey) {
            this.currentSurvey = _survey.id
            this.confirmDelete = true
        }
    },
};
</script>
