<template>
    <v-card>
        <v-card-title>Nieuw interview</v-card-title>
        <v-card-text>
            <v-autocomplete v-model="survey.coach" :items="coaches" item-text="name" item-value="id" label="Coach"
                return-object @change="fetchCoacheesOfCoach()"></v-autocomplete>
            <v-autocomplete v-model="survey.coachee" :items="coachees" item-text="name" item-value="id" return-object
                label="Coachee" />
        </v-card-text>
        <v-card-actions>
            <v-btn :disabled="!survey.coach.id || !survey.coachee.id" plain @click="addSurvey">Bevestigen</v-btn>
            <v-spacer />
            <v-btn plain @click="$emit('hideDialogAdd')">Annuleren</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { db } from "@/services/firebase";
import { collection, query, where, addDoc, getDocs } from "firebase/firestore";
import { mapGetters } from "vuex";
export default {
    name: "AddSurvey",
    data() {
        return {
            survey: {
                templateId: "TPL-001",
                coach: {},
                coachee: {},
            },
            coaches: [],
            coachees: [],
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "session/userInfo",
        }),
    },
    methods: {
        async addSurvey() {
            await addDoc(collection(db, "surveys"), this.survey);
            this.$emit("hideDialogAdd");
            this.$emit("refresh");
        },
        async fetchCoaches() {
            switch (this.userInfo.role) {
                case "admin":
                    const _collection = collection(db, "coaches");
                    const _coaches = await getDocs(_collection);
                    this.coaches = _coaches.docs.map((doc) => {
                        return {
                            id: doc.id,
                            name:
                                doc.data().name.firstName +
                                " " +
                                doc.data().name.lastName,
                        };
                    });
                    break;

                case "coach":
                    this.coaches = [];
                    this.coaches.push(this.userInfo.coach);
                    this.survey.coach = this.userInfo.coach;
                    this.fetchCoacheesOfCoach();
                    break;

                default:
                    this.coaches = [];
            }
        },
        async fetchCoacheesOfCoach() {
            const q = query(
                collection(db, "coachees"),
                where("coach.id", "==", this.survey.coach.id)
            );
            const _coachees = await getDocs(q);
            this.coachees = _coachees.docs.map((doc) => {
                return {
                    id: doc.id,
                    name:
                        doc.data().name.firstName +
                        " " +
                        doc.data().name.lastName,
                };
            });
            if (this.userInfo.admin) {
                this.coachees.push(this.userInfo.admin)
            }
            if (this.userInfo.coach) {
                this.coachees.push(this.userInfo.coach)
            }

        },
    },
    created() {
        this.fetchCoaches();
    },
};
</script>
