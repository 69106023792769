<template>
    <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
            <v-btn plain @click="cancel">Annuleren</v-btn>
            <v-spacer />
            <v-btn plain @click="confirm">Verwijderen</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {

        title: {
            type: String,
            default: 'Verwijderen',
        },
        message: {
            type: String,
            default: 'OK om te verwijderen?',
        },
    },
    methods: {
        confirm() {
            this.$emit('confirm');
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>
